.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form_fields {
    display: flex;
    flex-direction: column;
    width: 100%
}
.submit{
    width: 400px;
    height: 40px;
}